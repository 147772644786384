/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/style/layout.css"

import React from "react"
import {
    GoogleReCaptchaProvider
  } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => (
    <GoogleReCaptchaProvider reCaptchaKey="6LdY9QMfAAAAAMyixlV55yeF1D-W4gV0TcGtt1kr">
        {element}
    </GoogleReCaptchaProvider>
)
