const theme = {
  colors: {
    text: "#231F20",
    textLight: "#FFFFFF",
    textPrimary: "#FF5724",
    background: "#ffffff",
    lightBackground: "#FFFFFF",
    grayBackground: "#F2F5F7",
    primary: "#D65129",
    primaryLight: "#F3987D",
    secondary: "#231F20",
    light: "#ffffff",
    dark: "#231F20",
    gray: "rgba(21, 33, 43,.1)",
    lightGrey: "#999999",
  },
  fonts: {
    body: 'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      'Urbanist, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 148, 192, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96, 128, 148, 164, 196, 228, 256],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    p: {
      fontSize: [1],
      lineHeight: "1.5",
      fontFamily: "body",
      fontWeight: "body",
      letterSpacing: "2px",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [5, 6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontFamily: "heading",
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontFamily: "heading",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    sectionTitleBlack: {
      h2: {
        margin: "inherit",
        fontFamily: "heading",
        fontSize: [6],
        letterSpacing: "-.5px",
        lineHeight: "1.12",
        em: {
          fontStyle: "normal",
          color: "dark",
        },
      },
      span: {
        display: "block",
      },
      p: {
        margin: "inherit",
        fontSize: [1],
        letterSpacing: "2px",
        lineHeight: "1.4",
        em: {
          fontStyle: "normal",
          color: "primary",
        },
      },
      ul: {
        p: 0,
        listStyleType: "none",
        margin: "inherit",
        fontSize: [2, 3],
        lineHeight: "36px",
        em: {
          fontStyle: "normal",
          color: "dark",
        },
        ml: [3, 0],
        li: {
          color: "dark",
          textTransform: "uppercase",
          fontWeight: "600",
          position: "relative",
          "span:before": {
            content: "'+'",
            color: "primaryLight",
            ml: [1],
            position: "absolute",
            left: [-3, -4, -4, -4],
          },
        },
      },
    },
    sectionTitle: {
      h2: {
        margin: "inherit",
        fontFamily: "heading",
        fontSize: [6],
        letterSpacing: "-.5px",
        lineHeight: "1.12",
        em: {
          fontStyle: "normal",
          color: "primary",
        },
      },
      a: {
        color: "primary",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      span: {
        display: "block",
      },
      p: {
        fontFamily: "body",
        margin: "inherit",
        fontSize: [1],
        letterSpacing: "2px",
        lineHeight: "1.4",
        em: {
          fontStyle: "normal",
          color: "primary",
        },
      },
      ul: {
        p: 0,
        listStyleType: "none",
        margin: "inherit",
        fontSize: [1],
        letterSpacing: "2px",
        lineHeight: "1.7",
        em: {
          fontStyle: "normal",
          color: "primary",
        },
        ml: [1],
        li: {
          position: "relative",
          span: {
            ml: [3, 3, 3, 3],
          },
          "span:before": {
            content: "'//'",
            color: "primary",
            position: "absolute",
            left: [-2, -2, -2, -2],
          },
        },
      },
    },
    caption: {
      fontSize: 1,
      color: "primary",
      letterSpacing: "2.5px",
      textTransform: "uppercase",
      fontWeight: "bold",
      "&:before": {
        content: "'// '",
      },
      "&:after": {
        content: "' //'",
      },
      black: {
        fontSize: 1,
        color: "dark",
        letterSpacing: "2.5px",
        textTransform: "uppercase",
        fontWeight: "bold",
        "&:before": {
          content: "'// '",
        },
        "&:after": {
          content: "' //'",
        },
      },
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      maxWidth: "1178px",
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  inputs: {
    primary: {
      borderColor: "primary",
      p: [3],
      border: "1px solid",
      borderRadius: "0px!important",
      cursor: "pointer",
      color: "primary",
      "&:focus": {
        outline: "none",
        backgroundColor: "primary",
        color: "white",
        border: "1px solid transparent",
      },
    },
  },
  buttons: {
    full: {
      default: {
        padding: 2,
      },
      primary: {
        paddingY: 3,
        paddingX: 5,
        cursor: "pointer",
        color: "light",
        bg: "primary",
        "&:hover": {
          bg: "dark",
        },
      },
      primaryForm: {
        paddingY: 3,
        paddingX: 5,
        cursor: "pointer",
        color: "light",
        bg: "primary",
        "&:hover": {
          color: "light",
          bg: "primary",
          svg: {
            stroke: "light",
          },
        },
      },
    },
    primary: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "primary",
      "&:hover": {
        color: "white",
      },
      "&:after": {},
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",

      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      "*": {
        

        "-webkit-transition": "background-color .2s linear",
        "-ms-transition": "background-color .2s linear",
        transition: "background-color .2s linear",
      },
      backgroundColor: "dark",
      body: {},
      "a.active": {
        color: "primary",
      },
      fontFamily: "body",
      fontWeight: "body",
      a: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": { pb: 5 },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "dark",
        opacity: 1 /* Firefox */,
      },

      ":-ms-input-placeholder": {
        /* Internet Explorer 10-11 */ color: "dark",
      },

      "::-ms-input-placeholder": {
        /* Microsoft Edge */ color: "dark",
      },
    },
  },
}

export default theme
